@font-face {
  font-family: 'Mulish';
  src:
    local(''),
    url('/assets/fonts/mulish-v12-vietnamese_latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/assets/fonts/mulish-v12-vietnamese_latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'PlusJakartaSans';
  src:
    local(''),
    url('/assets/fonts/PlusJakartaSans-VariableFont_wght.ttf')
      format('truetype'),
    url('/assets/fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Inter';
  src:
    local(''),
    url('/assets/fonts/Inter/static/Inter-Medium.ttf') format('truetype'),
    url('/assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src:
    local(''),
    url('/assets/fonts/Inter/static/Inter-Regular.ttf') format('truetype'),
    url('/assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src:
    local(''),
    url('/assets/fonts/Inter/static/Inter-SemiBold.ttf') format('truetype'),
    url('/assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 600;
}

%font-default {
  font-family: 'Inter', 'PlusJakartaSans', 'Mulish', sans-serif;
}
* {
  @extend %font-default;
}

::-webkit-input-placeholder {
  @extend %font-default;
}

:-ms-input-placeholder {
  @extend %font-default;
}

:-moz-placeholder {
  @extend %font-default;
}

::-moz-placeholder {
  @extend %font-default;
}

