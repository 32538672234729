/* Fonts */
@import 'partials/core-font';

/* Partials */
@import 'partials/core-border';
@import 'partials/core-button';
@import 'partials/core-special';
@import 'partials/core-input';
@import 'partials/core-modal';
@import 'partials/custom-primeng';
@import 'partials/custom-flags';
@import 'partials/core-scroll';
@import 'partials/core-animation';
@import 'partials/custom-swiper';
